function InfoText({ info, addButtonEffect }: { info?: string; addButtonEffect: boolean }) {
	return (
		<div className='dropdown dropdown-top dropdown-end'>
			<div
				tabIndex={0}
				className={`inline-block mx-1 text-info ${
					addButtonEffect && 'btn-circle btn-ghost btn-xs'
				}`}>
				<svg
					xmlns='http:www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'
					className='inline w-5 h-5 stroke-lightblue/90'>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
				</svg>
			</div>
			{info && (
				<div tabIndex={0} className='py-2 dropdown-content transform translate-x-7 ml-10 '>
					<div className='shadow-xl w-72 card compact bg-neutral-focus text-neutral-content rounded-box'>
						<div className='card-body'>
							<p className='text-sm text-neutral-content pb-2'>{info}</p>{' '}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default InfoText
